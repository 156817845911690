import { graphql, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import styled from 'styled-components'
import FilterNav from '../../../components/FilterNav'
import SectionsContainer from '../../../components/SectionsContainer'

const Wrapper = styled(SectionsContainer)`
  .section {
    max-width: ${(props) => props.theme.containerWidths.medium};
  }
  .registration-iframe {
    margin-top: 24px;
  }
  .conditions {
    font-style: italic;
    font-size: 0.8rem;
    margin-left: 10px;
  }
  .registration-info {
    max-width: 340px;
    margin: auto;
    padding-top: 24px;
  }
`

const GridTable = styled.article`
  display: grid;
  grid-template-columns: max-content auto;
  text-align: left;
  grid-gap: 10px 38px;
  margin-top: 20px;
  p {
    margin: 0;
  }
  .th {
    font-weight: 600;
  }
  ~ .headline--reunion {
    margin-top: 40px;
  }
  ul:not([class]) {
    margin: 0;
    + p {
      margin: 0;
    }
  }
  & + p {
    margin-top: 24px;
  }
  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    grid-gap: 15px 80px;
  }
`

const Tickets = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulAsset(
        sort: { fields: [description], order: [ASC] },
        filter: { description: { regex: "/.*love-plus.*/" } }
      ) {
        edges {
          node {
            id
            title
            file {
              url
            }
          }
        }
      }
    }
  `)

  const ticketOptions = {
    plus: {
      openDate: new Date(2023, 9, 17, 10, 0, 0),
      closeDate: new Date(2024, 1, 1, 23, 59, 59),
      description: 'Plus Ticket',
      price: '$85 USD or $119 CAD',
      includes: [
        'LIVE virtual meeting streaming',
        'On-demand content viewing for 30 days post event',
        'LOVE Swag Gift - LOVE robe + LOVE socks + LOVE Event Pin',
      ],
      images: data.allContentfulAsset.edges,
      ticketUrl: 'https://www.seintofficial.com/en/office/events/register/',
    },
    basic: {
      openDate: new Date(2023, 9, 17, 10, 0, 0),
      closeDate: new Date(2024, 1, 1, 23, 59, 59),
      description: 'Basic Ticket',
      price: '$35 USD or $49 CAD',
      includes: [
        'LIVE virtual meeting streaming',
        'On-demand content viewing for 30 days post event',
      ],
      images: [],
      ticketUrl: 'https://www.seintofficial.com/en/office/events/register/',
    },
  }

  const today = new Date()
  const robeSizeGuide = data.allContentfulAsset.edges.slice(-1)[0]

  const [ticketOption, setTicketOption] = useState(
    ticketOptions.plus.closeDate > today ? 0 : 1
  )

  const handleTicketOptions = (index) => {
    index !== ticketOption && setTicketOption(index)
  }

  return (
    <Wrapper>
      <section className="section wrap first-section">
        <h3 className="headline--reunion center-align">Event Tickets</h3>
        <FilterNav>
          {Object.values(ticketOptions).map((option, i) => (
            <button
              key={i}
              className={i === ticketOption ? 'is-active' : null}
              onClick={() => handleTicketOptions(i)}
            >
              {option.description}
            </button>
          ))}
        </FilterNav>
        {Object.values(ticketOptions).map((option, i) => (
          <>
            <GridTable style={{ display: ticketOption === i ? 'grid' : 'none' }} className="body-content">
              <p className="th">Price</p>
              <p>{option.price}</p>
              <p className="th">Available</p>
              <p>
                {option.openDate.toLocaleString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}, {option.openDate.toLocaleTimeString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                })} MDT{' - '}
                {option.closeDate.toLocaleString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}, {option.closeDate.toLocaleTimeString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                })} MST
              </p>
              <p className="th">Includes</p>
              <ul>
                {option.includes.map((included) => (
                  <li>{included}</li>
                ))}
              </ul>
              <p></p>
              <ol className="conditions">
                <li>*All ticket sales are final. We will not be facilitating cancellations, refunds, name changes, or size exchanges.</li>
              </ol>
            </GridTable>
            <p style={{ display: ticketOption === i ? 'block' : 'none', textAlign: 'center' }}>
              <a
                className="btn--submit"
                style={{ textDecoration: 'none' }}
                href={option.ticketUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Register for LOVE ↗
              </a>
            </p>
            <div style={{ display: ticketOption === i ? 'block' : 'none', textAlign: 'center', marginTop: '20px' }}>
              {option.images.map(image => (
                <>
                  {(image != robeSizeGuide &&
                    <img style={{ display: 'inline-block', padding: '5px 10px', maxWidth: '350px', margin: 'auto' }} src={image.node.file.url} alt={image.node.title} />
                  )}
                  {(image == robeSizeGuide &&
                    <img style={{ display: 'inline-block', padding: '5px 10px', maxWidth: '600px', margin: 'auto' }} src={image.node.file.url} alt={image.node.title} />
                  )}
                </>
              ))}
            </div>
          </>
        ))}
      </section>
    </Wrapper >
  )
}

export default Tickets
